import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { authenticate } from '../actions/security'
import LoginForm from './LoginForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import ResetPasswordForm from './ResetPasswordForm'
import SignUp from './SignUpForm'
import { getLoginPageElement } from '../api/login'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setBrowserTabTitle } from '../../../helpers/setBrowserTabTitle'

const formStyles = theme => ({
  main: {
    flex: '0 0 50%',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '40px',
    position: 'relative',
  },
  '@media (min-width: 320px) and (max-width: 480px)': {
    main: {
      flex: '0 0 100%',
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '15px',
      position: 'relative',
    },
  },
  formTitle: {
    fontSize: '18px',
    fontWeight: '500',
    margin: 0,
    color: theme.tabs.login.formTitle()
  },
  demoForm: {
    width: '60%',
    margin: '0 0 32px',
    textAlign: 'center',
  },
  demoDiv: {
    margin: '32px 0 8px',
    textAlign: 'left',
  },
  bottomText: {
    position: 'absolute',
    bottom: '60px',
  },
  forgotPasswordLink: {
    fontSize: '13px',
    color: theme.tabs.login.forgotPassword.link,
    display: 'block',
    textAlign: 'center',
    marginTop: '30px',
  },
  forgotPasswordNoteText: {
    fontSize: '14px',
    color: theme.tabs.login.forgotPassword.noteText(),
    marginTop: '-10px',
    marginBottom: '25px',
    fontWeight: '300',
  },
})

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      from: null,
      title: null,
      subTitle: null,
      imagePath: null,
      isSignUpEnable: false,
      redirectToReferrer: false,
    }
  }

  componentDidMount() {
    const from = this.props?.location?.state?.from?.pathname
    const authenticated = JSON.parse(localStorage.getItem('authenticated'))
    setBrowserTabTitle('Chatbot Platform')
    if (authenticated) {
      this.authenticateWithReferrer(from)
    } else {
      getLoginPageElement(element => {
        this.setState({
          title: element?.title,
          subTitle: element?.subTitle,
          imagePath: element?.imagePath,
          isSignUpEnable: element?.isSignUpEnable,
        })
      })
    }
  }

  authenticateWithReferrer = (from) => {
    authenticate()
    this.setState({ redirectToReferrer: true, from })
  }

  render() {
    const { classes } = this.props
    const {
      redirectToReferrer,
      title,
      subTitle,
      imagePath,
      isSignUpEnable,
      from,
    } = this.state

    if (redirectToReferrer) {
      return <Redirect to={from || 'admin'} />
    }

    return (
      <div className="main container-fluid">
        {title && subTitle && imagePath && (
          <div className="row row--main">
            <section
              style={{
                backgroundImage: 'url("https://getskitickets.com/wp-content/uploads/AI-for-Mountain-Travel.png")',
                backgroundRepeat: 'no-repeat'
              }}
              className="title-section col-sm-6">
              <article className="section__inner">
                <h1 className="title--main">{title}</h1>
                <h4 className="title--small">{subTitle}</h4>
              </article>
            </section>
            <Switch>
              <Route
                path={'/sign-up/:code'}
                component={props => (
                  <SignUp {...props} invited={true} styles={classes} />
                )}
              />
              <PrivateRoute
                isSignUpEnable={isSignUpEnable}
                exact
                path="/sign-up"
                component={() => <SignUp styles={classes} />}
              />
              <Route
                path={'/login'}
                component={() => (
                  <LoginForm
                    authenticateWithReferrer={this.authenticateWithReferrer}
                    isSignUpEnable={isSignUpEnable}
                    styles={classes}
                  />
                )}
              />
              <Route
                path="/forgot-password"
                component={props => <ForgotPasswordForm styles={classes} />}
              />
              <Route
                path="/reset-password"
                component={props => <ResetPasswordForm styles={classes} />}
              />
            </Switch>
          </div>
        )}
      </div>
    )
  }
}

const PrivateRoute = ({ component: Component, isSignUpEnable, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (isSignUpEnable ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      ))
    }
  />
)
Login.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  security: state.security,
})

export default withStyles(formStyles, { withTheme: true })(connect(mapStateToProps)(Login))
