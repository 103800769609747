import React from 'react'
import classes from '../GreetingMessagePopupPreviewScreen/styles.module.scss'
import { GreetingType } from 'models/WidgetSettingsTypes'

interface PopupLogoPreviewProps {
  greetingPopupLogoUrl: string | null
}

const PopupLogoPreview: React.FC<PopupLogoPreviewProps> = ({ greetingPopupLogoUrl }) => {
  return greetingPopupLogoUrl ? (
    <img src={greetingPopupLogoUrl} alt="logo" className={classes.previewLogo} />
  ) : (
    <div className={classes.previewLogo}></div>
  )
}

const HorizontalArrow = () => {
  return (
    <svg width="58" height="8" viewBox="0 0 58 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line */}
      <path d="M57.3536 4.35355C57.5488 4.15829 57.5488 3.84171 57.3536 3.64645L54.1716 0.464466C53.9763 0.269204 53.6597 0.269204 53.4645 0.464466C53.2692 0.659728 53.2692 0.976311 53.4645 1.17157L56.2929 4L53.4645 6.82843C53.2692 7.02369 53.2692 7.34027 53.4645 7.53553C53.6597 7.7308 53.9763 7.7308 54.1716 7.53553L57.3536 4.35355ZM1 4.5H57V3.5H1V4.5Z" fill="#464960" />
      {/* eslint-disable-next-line */}
      <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM8 3.5L1 3.5L1 4.5L8 4.5L8 3.5Z" fill="#464960" />
    </svg>
  )
}

const VerticalArrow = () => {
  return (
    <svg width="8" height="58" viewBox="0 0 8 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line */}
      <path d="M3.64644 57.3536C3.84171 57.5488 4.15829 57.5488 4.35355 57.3536L7.53553 54.1716C7.73079 53.9763 7.73079 53.6597 7.53553 53.4645C7.34027 53.2692 7.02369 53.2692 6.82842 53.4645L4 56.2929L1.17157 53.4645C0.976308 53.2692 0.659726 53.2692 0.464464 53.4645C0.269202 53.6597 0.269202 53.9763 0.464464 54.1716L3.64644 57.3536ZM3.5 1L3.5 57L4.5 57L4.5 1L3.5 1Z" fill="#464960" />
      {/* eslint-disable-next-line */}
      <path d="M4.35355 0.646447C4.15829 0.451184 3.84171 0.451184 3.64645 0.646447L0.464467 3.82843C0.269204 4.02369 0.269204 4.34027 0.464467 4.53553C0.659729 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646447ZM4.5 8L4.5 1L3.5 1L3.5 8L4.5 8Z" fill="#464960" />
    </svg>
  )
}

const CloseButtonPreview = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line */}
      <path fillRule="evenodd" clipRule="evenodd" d="M6.99992 1.66659C4.0544 1.66659 1.66659 4.0544 1.66659 6.99992C1.66659 9.94544 4.0544 12.3333 6.99992 12.3333C9.94544 12.3333 12.3333 9.94544 12.3333 6.99992C12.3333 4.0544 9.94544 1.66659 6.99992 1.66659ZM0.333252 6.99992C0.333252 3.31802 3.31802 0.333252 6.99992 0.333252C10.6818 0.333252 13.6666 3.31802 13.6666 6.99992C13.6666 10.6818 10.6818 13.6666 6.99992 13.6666C3.31802 13.6666 0.333252 10.6818 0.333252 6.99992Z" fill="#222222" />
      {/* eslint-disable-next-line */}
      <path fillRule="evenodd" clipRule="evenodd" d="M5.19518 5.19518C5.45553 4.93483 5.87764 4.93483 6.13799 5.19518L6.99992 6.05711L7.86185 5.19518C8.1222 4.93483 8.54431 4.93483 8.80466 5.19518C9.06501 5.45553 9.06501 5.87764 8.80466 6.13799L7.94273 6.99992L8.80466 7.86185C9.06501 8.1222 9.06501 8.54431 8.80466 8.80466C8.54431 9.06501 8.1222 9.06501 7.86185 8.80466L6.99992 7.94273L6.13799 8.80466C5.87764 9.06501 5.45553 9.06501 5.19518 8.80466C4.93483 8.54431 4.93483 8.1222 5.19518 7.86185L6.05711 6.99992L5.19518 6.13799C4.93483 5.87764 4.93483 5.45553 5.19518 5.19518Z" fill="#222222" />
    </svg>
  )
}

interface Props {
  bottomSpacing: number
  sideSpacing: number
  greetingPopupLogoUrl: string | null
  alignment: 'LEFT' | 'RIGHT'
  greetings: GreetingType[]
}

const GreetingMessagePopupPreviewScreen: React.FC<Props> = ({
  bottomSpacing,
  sideSpacing,
  greetingPopupLogoUrl,
  alignment,
  greetings
}) => {

  const greetingText = greetings[0].greetingText

  return alignment === 'LEFT' ? (
    <div className={classes.container}>
      <div className={classes.logoPlacement}>
        <div className={classes.block1}>
          <HorizontalArrow />
          <p>{sideSpacing}</p>
        </div>
        <div className={classes.block2}>
          <PopupLogoPreview greetingPopupLogoUrl={greetingPopupLogoUrl} />
        </div>
        <div className={classes.block3}></div>
        <div className={classes.block4}>
          <div>
            <VerticalArrow />
          </div>
          <p>{bottomSpacing}</p>
        </div>
      </div>
      <div>
        <div className={classes.greetingMessagePreview}>
          <p className={classes.message}>{greetingText}</p>
          <div className={classes.closeButtonPreview}>
            <CloseButtonPreview />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.container}>
      <div>
        <div className={classes.greetingMessagePreview}>
          <div className={classes.closeButtonPreviewRightSide}>
            <CloseButtonPreview />
          </div>
          <p className={classes.message}>{greetingText}</p>
        </div>
      </div>
      <div className={classes.logoPlacement}>
        <div className={classes.block1RightSide}>
          <PopupLogoPreview greetingPopupLogoUrl={greetingPopupLogoUrl} />
        </div>
        <div className={classes.block2RightSide}>
          <HorizontalArrow />
          <p>{sideSpacing}</p>
        </div>
        <div className={classes.block3RightSide}>
          <div>
            <VerticalArrow />
          </div>
          <p>{bottomSpacing}</p>
        </div>
        <div className={classes.block4RightSide}></div>
      </div>
    </div>
  )
}

export default GreetingMessagePopupPreviewScreen
