import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Switch from 'uiKit/Switch'
import Input from 'uiKit/inputs/Input'

import Paragraph from '../../../../uiKit/texts/Paragraph'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { BACKEND_URL, WIDGET_BASE_URL } from '../../../../configs'
import { alertSuccess } from '../../../../api'

import classes from './styles.module.scss'
import { WidgetSettingsType } from '../../../../models/WidgetSettingsTypes'

interface Props {
  widgetSettings: WidgetSettingsType
  activeBot: any
}

const CopySnippet: React.FC<Props> = ({ widgetSettings, activeBot }) => {
  const getScriptSrcAttr = (): string => WIDGET_BASE_URL + '/script-chatbot.js'
  const [isEmbedded, setIsEmbedded] = useState(false)
  const [placeholderId, setPlaceholderId] = useState('')
  const handleToggleEmbeddedWidgetOption = () => {
    setIsEmbedded(!isEmbedded)
    alertSuccess(`Embedded widget option is ${!isEmbedded ? 'enabled' : 'disabled'}`)
  }

  const getEmbeddedParamsBeautifyString = (isEmbedded: boolean, placeholderId: string): string => {
    return isEmbedded ? `embedded="true" \n        placeholder-id="${placeholderId}"       
        embedded-width="100%25"\n        embedded-height="100%25">` : ''
  }

  const getEmbeddedParams = (isEmbedded: boolean, placeholderId: string): string => {
    return isEmbedded ? `embedded="true" placeholder-id="${placeholderId}"` +
    'embedded-width="100%25" embedded-height="100%25">' : ''
  }

  // eslint-disable-next-line max-len
  const scriptSnipped = `<script type="text/javascript" id="chatbot-initials-script" src="${getScriptSrcAttr()}" data-server-url="${BACKEND_URL}" data-bot-id="${
    activeBot?.publicIdentifier}" ${getEmbeddedParams(isEmbedded, placeholderId)}</script>`

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(scriptSnipped)
    alertSuccess('Copied!')
  }

  return (
    <div>
      <Paragraph style={{ margin: '4px 0 8px 0' }}>Copy widget code snippet to clipboard</Paragraph>
      <pre className={classes.snippet}>
        {`<script type="text/javascript" 
        id="chatbot-initials-script" 
        src="${widgetSettings?.scriptChatbotUrl}" 
        data-server-url="${BACKEND_URL}" 
        data-bot-id="${activeBot?.publicIdentifier}"
        ${isEmbedded ? getEmbeddedParamsBeautifyString(isEmbedded, placeholderId) : ''}</script>`}
      </pre>
      <div className={classes.embeddedSelectorContainer}>
        <Switch
          useSecondaryColor={true}
          label="Embedded widget enable"
          checked={isEmbedded}
          onChange={handleToggleEmbeddedWidgetOption}
        />
        {isEmbedded && <Input
          title="Embedded widget placeholder id"
          style={{ width: 300, marginBottom: '1rem' }}
          value={placeholderId}
          placeholder="Enter id"
          onChange={(e) => setPlaceholderId(e.target.value)}
        />}
      </div>
      <SubmitButton title="Copy to clipboard" onClick={handleCopyToClipboard} />
    </div>
  )
}

const mapStateToProps = (state: { activeBot: any; widgetSettings: WidgetSettingsType }) => ({
  activeBot: state.activeBot,
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(CopySnippet))
