import React, { useEffect, useState } from 'react'
import classes from '../GreetingMessagePopupAppearanceSection/styles.module.scss'
import Switch from '../../../../uiKit/Switch'
import LogoDropzone from '../LogoDropzone'
import Label from '../../../../uiKit/texts/Label'
import Input from '../../../../uiKit/inputs/Input'
import Tooltip from '../../../../uiKit/TooltipInfo/Tooltip'
import RoundCheckbox from '../../../../uiKit/inputs/RoundCheckbox'
import GreetingMessagePopupPreviewScreen from '../GreetingMessagePopupPreviewScreen'
import { WidgetSettingsType } from '../../../../models/WidgetSettingsTypes'
import { OptionItemType } from '../../../../models/MessageTypes'
import { setErrorsPxField } from '../../../../helpers/setErrors/setErrors'

interface Props {
  widgetSettings: WidgetSettingsType
  selectedLanguage: OptionItemType
  handleUpdateWidgetSettings: (value: string | boolean, field: string) => void
}

const GreetingMessagePopupAppearanceSection: React.FC<Props> = ({
  widgetSettings,
  selectedLanguage,
  handleUpdateWidgetSettings,
}) => {
  const [logoSize, setLogoSize] = useState<number | null>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.popupLogoSize || null,
  )
  const [alignment, setAlignment] = useState<'LEFT' | 'RIGHT'>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.alignment || null,
  )
  const [bottomSpacing, setBottomSpacing] = useState<number | null>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.bottomSpacing || null,
  )
  const [sideSpacing, setSideSpacing] = useState<number | null>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.sideSpacing || null,
  )
  const [errorsLogoSize, setErrorsLogoSize] = useState<string[]>([])
  const [errorsBottomSpacing, setErrorsBottomSpacing] = useState<string[]>([])
  const [errorsSideSpacing, setErrorsSideSpacing] = useState<string[]>([])

  useEffect(() => {
    handleUpdateAlignment()
  }, [alignment])

  const handleUpdateLogoSize = (value: number) => {
    const isValid = validateLogoSizeField(value, setErrorsLogoSize)

    if (isValid) {
      handleUpdateWidgetSettings(value.toString(10), 'greetingMessagePopupSettings.popupLogoSize')
    }
  }

  const handleUpdateAlignment = () => {
    handleUpdateWidgetSettings(alignment, 'greetingMessagePopupSettings.alignment')
  }

  const handleUpdateBottomSpacing = (value: number) => {
    const isValid = validateSpacingField(value, setErrorsBottomSpacing)

    if (isValid) {
      handleUpdateWidgetSettings(value.toString(10), 'greetingMessagePopupSettings.bottomSpacing')
    }
  }

  const handleUpdateSideSpacing = (value: number) => {
    const isValid = validateSpacingField(value, setErrorsSideSpacing)

    if (isValid) {
      handleUpdateWidgetSettings(value.toString(10), 'greetingMessagePopupSettings.sideSpacing')
    }
  }

  const handleLogoSize = event => {
    const inputValue = event.target.value.replace('px', '')
    validateLogoSizeField(inputValue, setErrorsLogoSize)
    const numericValue = parseInt(inputValue, 10)

    if (!isNaN(numericValue)) {
      setLogoSize(numericValue)
    } else {
      setLogoSize(null)
    }
  }

  const handleBottomSpacing = event => {
    const inputValue = event.target.value.replace('px', '')
    validateSpacingField(inputValue, setErrorsBottomSpacing)
    const numericValue = parseInt(inputValue, 10)

    if (!isNaN(numericValue)) {
      setBottomSpacing(numericValue)
    } else {
      setBottomSpacing(null)
    }
  }

  const handleSideSpacing = event => {
    const inputValue = event.target.value.replace('px', '')
    validateSpacingField(inputValue, setErrorsSideSpacing)
    const numericValue = parseInt(inputValue, 10)

    if (!isNaN(numericValue)) {
      setSideSpacing(numericValue)
    } else {
      setSideSpacing(null)
    }
  }

  const validateLogoSizeField = (value: number, callback: (a: any) => void) => {
    const errors = setErrorsPxField(value, 40, 100)
    callback(errors)

    return !errors.length
  }

  const validateSpacingField = (value: number, callback: (a: any) => void) => {
    const errors = setErrorsPxField(value, 24, 300)
    callback(errors)

    return !errors.length
  }

  return (
    <div className={classes.greetingMessagePopupSection}>
      <h2 className={classes.subHeaderText}>Greeting message popup</h2>
      <hr className={classes.horizontalLine} />
      <div className={classes.greetingMessagePopupSectionContainer}>
        <div>
          <Switch
            label="Show greeting message popup"
            checked={widgetSettings?.languageConfigs[selectedLanguage.value]?.doShowPopupMessagePreview}
            onChange={e => handleUpdateWidgetSettings(e.target.checked, 'doShowPopupMessagePreview')}
          />
          <div className={classes.logoSection}>
            <LogoDropzone
              value={
                widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.popupLogoUrl || ''
              }
              onChange={(value: string) =>
                handleUpdateWidgetSettings(value, 'greetingMessagePopupSettings.popupLogoUrl')
              }
              dropzoneType={'POPUP_LOGO'}
            />
            <span className={classes.logoSizeInput}>
              <Input
                title="Logo size"
                placeholder="Write logo size in pixels (e.g. 72 px)"
                value={logoSize === null ? '' : `${logoSize}px`}
                onChange={handleLogoSize}
                handleEnterPress={() => handleUpdateLogoSize(logoSize)}
                onBlur={() => handleUpdateLogoSize(logoSize)}
                error={!!errorsLogoSize.length}
                errorMessage={errorsLogoSize[0]}
                tooltipText={'Customize logo size. Set the logo size between 40 and 100 pixels.'}
              />
            </span>
          </div>
          <div className={classes.popupPositioningSection}>
            <Label>
              Greeting message popup position
              <Tooltip
                tooltipId={'TooltipInfo-attribute'}
                tooltipText={
                  'Customize greeting popup position. ' + 'Align it to the left or right side of the screen.'
                }
              />
            </Label>
            <Label>Align to</Label>
            <div className={classes.alignSection}>
              <RoundCheckbox label={'Left'} checked={alignment === 'LEFT'} onChange={() => setAlignment('LEFT')} />
              <span className={classes.rightCheckbox}>
                <RoundCheckbox label={'Right'} checked={alignment === 'RIGHT'} onChange={() => setAlignment('RIGHT')} />
              </span>
            </div>
            <div className={classes.spacingSection}>
              <div className={classes.bottomSpacing}>
                <Input
                  title="Bottom spacing"
                  placeholder="Write logo size in pixels (e.g. 72 px)"
                  value={bottomSpacing === null ? '' : `${bottomSpacing}px`}
                  onChange={handleBottomSpacing}
                  handleEnterPress={() => handleUpdateBottomSpacing(bottomSpacing)}
                  onBlur={() => handleUpdateBottomSpacing(bottomSpacing)}
                  error={!!errorsBottomSpacing.length}
                  errorMessage={errorsBottomSpacing[0]}
                  tooltipText={'Set the spacing from the side to be between 24 and 300 pixels.'}
                />
              </div>
              <div className={classes.sideSpacing}>
                <Input
                  title="Side spacing"
                  placeholder="Write logo size in pixels (e.g. 72 px)"
                  value={sideSpacing === null ? '' : `${sideSpacing}px`}
                  onChange={handleSideSpacing}
                  handleEnterPress={() => handleUpdateSideSpacing(sideSpacing)}
                  onBlur={() => handleUpdateSideSpacing(sideSpacing)}
                  error={!!errorsSideSpacing.length}
                  errorMessage={errorsSideSpacing[0]}
                  tooltipText={'Set the spacing from the side to be between 24 and 300 pixels.'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.greetingMessagePopupPreviewScreenContainer}>
          <GreetingMessagePopupPreviewScreen
            bottomSpacing={bottomSpacing}
            sideSpacing={sideSpacing}
            greetingPopupLogoUrl={
              widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.popupLogoUrl || null
            }
            alignment={alignment}
            greetings={widgetSettings?.languageConfigs[selectedLanguage.value].greetings}
          />
        </div>
      </div>
    </div>
  )
}

export default GreetingMessagePopupAppearanceSection
