import React, { useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Heading from '../../../../uiKit/texts/Heading'
import Input from '../../../../uiKit/inputs/Input'
import LanguageSelector from '../../../../uiKit/LanguageSelector/LanguageSelector'
import LogoDropzone from '../LogoDropzone'
import ColorSelector from '../ColorSelector'
import WidgetChatScreen from '../WidgetChatScreen'
import { setErrors } from '../../../../helpers/setErrors/setErrors'
import { updateWidget } from '../../api/widget'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { deepCompare } from '../../../../helpers/deepCompare'

import classes from './styles.module.scss'
import { WidgetSettingsType } from '../../../../models/WidgetSettingsTypes'
import Switch from '../../../../uiKit/Switch'
import GreetingMessagePopupAppearanceSection from '../GreetingMessagePopupAppearanceSection'

interface Props {
  match: any
  widgetSettings: WidgetSettingsType
}

const AppearanceTab: React.FC<Props> = ({ match, widgetSettings }) => {
  const [selectedLanguage, setSelectedLanguages] = useState({label: 'English (default)', value: 'en'})
  const languageConfig = widgetSettings?.languageConfigs[selectedLanguage.value]
  const [header, setHeader] = useState(languageConfig?.widgetName || '')
  const [inputPlaceholder, setPlaceholder] = useState(languageConfig?.inputPlaceholder || '')
  const [errorsName, setErrorsName] = useState<string[]>([])
  const [errorsPlaceholder, setErrorsPlaceholder] = useState<string[]>([])

  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])

  const handleUpdateWidgetSettings = (value: string | boolean, field: string) => {
    if (field.startsWith('greetingMessagePopupSettings.')) {
      const popupSettingsField = field.slice('greetingMessagePopupSettings.'.length)
      widgetCopy.languageConfigs[selectedLanguage.value].greetingMessagePopupSettings[popupSettingsField] = value
    } else {
      widgetCopy.languageConfigs[selectedLanguage.value][field] = value
    }

    if (!deepCompare(widgetCopy, widgetSettings)) {
      updateWidget(match.params.botId, widgetCopy)
    }
  }

  const handleUpdateWidgetUserInput = (value: string | boolean) => {
    widgetCopy.isComposerInputEnabled = !value

    if (!deepCompare(widgetCopy, widgetSettings)) {
      updateWidget(match.params.botId, widgetCopy)
    }
  }

  const handleUpdateName = (value: string) => {
    const isValid = validateField(value, setErrorsName)

    if (isValid) {
      handleUpdateWidgetSettings(value, 'widgetName')
    }
  }

  const handleUpdatePlaceholder = (value: string) => {
    const isValid = validateField(value, setErrorsPlaceholder)

    if (isValid) {
      handleUpdateWidgetSettings(value, 'inputPlaceholder')
    }
  }

  const handleChangeName = event => {
    validateField(event.target.value, setErrorsName)
    setHeader(event.target.value)
  }

  const handlePlaceholder = event => {
    validateField(event.target.value, setErrorsPlaceholder)
    setPlaceholder(event.target.value)
  }

  const handleChangeLanguage = language => {
    setSelectedLanguages(language)
    setHeader(languageConfig?.widgetName)
    setPlaceholder(languageConfig?.inputPlaceholder)
  }

  const validateField = (value: string, callback: (a: any) => void) => {
    const errors = setErrors(value, 20)
    callback(errors)

    return !errors.length
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Heading>Appearance</Heading>
        <LanguageSelector onChange={handleChangeLanguage} value={selectedLanguage} />
      </div>

      <p className={classes.paragraph}>
        Customize widget appearance that will be shown to the user based on his language
      </p>

      <div className={classes.subHeader}>
        <h2 className={classes.subHeaderText}>Widget</h2>
        <hr className={classes.horizontalLine}/>
      </div>

      <div className={classes.body}>
        <div className={classes.form}>
          <LogoDropzone
            value={languageConfig?.imageUrl || ''}
            onChange={(value: string) => handleUpdateWidgetSettings(value, 'imageUrl')}
            dropzoneType={'WIDGET_LOGO'}
          />

          <ColorSelector
            value={languageConfig?.color || ''}
            onChange={(value: string) => handleUpdateWidgetSettings(value, 'color')}
          />

          <Input
            title="Widget name"
            placeholder="Chatbot"
            value={header || ''}
            onChange={handleChangeName}
            handleEnterPress={() => handleUpdateName(header)}
            onBlur={() => handleUpdateName(header)}
            error={!!errorsName.length}
            errorMessage={errorsName[0]}
          />

          <Input
            title="Input placeholder"
            placeholder="Write your reply"
            value={inputPlaceholder || ''}
            onChange={handlePlaceholder}
            handleEnterPress={() => handleUpdatePlaceholder(inputPlaceholder)}
            onBlur={() => handleUpdatePlaceholder(inputPlaceholder)}
            error={!!errorsPlaceholder.length}
            errorMessage={errorsPlaceholder[0]}
          />

          <div className={classes.switchersContainer}>
            <Switch
              useSecondaryColor={true}
              label="Enable sound notifications"
              checked={languageConfig?.doEnableSoundNotifications}
              onChange={e => handleUpdateWidgetSettings(e.target.checked, 'doEnableSoundNotifications')}
            />
            <Switch
              useSecondaryColor={true}
              label="Ask for rating after conversation ends"
              checked={languageConfig?.doAskForRating}
              onChange={e => handleUpdateWidgetSettings(e.target.checked, 'doAskForRating')}
            />
            <Switch
              useSecondaryColor={true}
              label="Disable text input (for all languages)"
              checked={!widgetSettings?.isComposerInputEnabled}
              onChange={e => handleUpdateWidgetUserInput(e.target.checked)}
            />
          </div>
        </div>
        {widgetSettings && (
          <WidgetChatScreen languageConfigs={languageConfig}/>
        )}
      </div>
      <GreetingMessagePopupAppearanceSection
        widgetSettings={widgetSettings}
        selectedLanguage={selectedLanguage}
        handleUpdateWidgetSettings={handleUpdateWidgetSettings}
      />
    </div>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(AppearanceTab))
