import React, { useEffect, useRef } from 'react'
import moment from 'moment'

import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import { CopyIcon } from 'uiKit/icons/Icons'
import { DeleteIcon } from 'uiKit/icons/DeleteIcon'
import Tooltip from 'uiKit/Tooltip'

import { isEllipsisActive } from 'helpers/isEllipsisActive'
import { copyValueToClipboard } from 'helpers/copyValueToClipboard'
import { titles } from '../../../constants/apiTableTitles'
import { ApiKeyType } from 'models/ApiKeysType'

import * as S from './ApiKeysTable.style'

interface Props {
  apiKeysList: ApiKeyType[]
  onOpenDeleteModal: (a: boolean, b: string) => any
}

export const ApiKeysTable: React.FC<Props> = ({ apiKeysList, onOpenDeleteModal }) => {
  const nameRef = useRef([])
  const emailRef = useRef([])

  useEffect(() => {
    nameRef.current = nameRef.current.slice(0, apiKeysList.length)
    emailRef.current = emailRef.current.slice(0, apiKeysList.length)
  }, [apiKeysList])

  return (
    <Table titles={titles}>
      {apiKeysList.map((apiKey, index) => (
        <TableRow key={apiKey.hash} index={0} style={{ height: 36 }}>
          <TableCell style={{ maxWidth: 150 }}>
            <S.EllipsisText data-for={apiKey.name + index} data-tip ref={el => (nameRef.current[index] = el)}>
              {apiKey.name}
            </S.EllipsisText>
            {isEllipsisActive(nameRef.current[index]) && <Tooltip id={apiKey.name + index}>{apiKey.name}</Tooltip>}
          </TableCell>
          <TableCell style={{ maxWidth: 150 }}>
            <S.EllipsisText data-for={apiKey.hash + index} data-tip>
              <S.IconWrap onClick={() => copyValueToClipboard(apiKey.hash)}>
                <CopyIcon color={'var(--color-spun-pearl-grey)'} width={16} />
              </S.IconWrap>
              {apiKey.hash}
            </S.EllipsisText>
            <Tooltip id={apiKey.hash + index}>{apiKey.hash}</Tooltip>
          </TableCell>
          <TableCell style={{ maxWidth: 150 }}>
            <S.EllipsisText data-for={apiKey.createdBy + index} data-tip ref={el => (emailRef.current[index] = el)}>
              {apiKey.createdBy}
            </S.EllipsisText>
            {isEllipsisActive(emailRef.current[index]) && (
              <Tooltip id={apiKey.createdBy + index}>{apiKey.createdBy}</Tooltip>
            )}
          </TableCell>
          <TableCell style={{ maxWidth: 70 }}>{moment(apiKey.createdDateTime).format('MM.DD.YY')}</TableCell>
          <TableCell style={{ maxWidth: 20 }}>
            <S.IconWrap onClick={() => onOpenDeleteModal(true, apiKey.name)}>
              <DeleteIcon />
            </S.IconWrap>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
