import { setBotWidget, setAiChat } from '../actions/botWidget'
import { setWidgetDefaultSettings } from '../actions/widgetDefaultSettings'
import { alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson } from 'api'
import { BASE_URL, BACKEND_URL } from '../../../configs'

export function getAiChatConfig(botId) {
  return fetch(`${BACKEND_URL}/ai-chat/${botId}/config`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setAiChat(json)
    })
    .catch(() => alertError('Sorry but something going wrong at getting AI chat info, please ping support!'))
}

export function getWidget(botId) {
  return fetch(`${BASE_URL}/bot/${botId}/widget`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setBotWidget(json.data)
    })
    .catch(() => alertError('Sorry but something going wrong at getting widget info, please ping support!'))
}

export function updateWidget(botId, widget) {
  fetch(`${BASE_URL}/bot/${botId}/widget`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PATCH',
    body: JSON.stringify(widget),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setBotWidget(json.data)
      alertSuccess('Widget settings updated', 3000)
    })
    .catch(() => alertError('Sorry but something going wrong at getting widget info, please ping support!'))
}

export function getWidgetDefaultSettings(botId) {
  fetch(`${BASE_URL}/bot/${botId}/widget/default-translations`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setWidgetDefaultSettings(json.data)
    })
    .catch(() => alertError('Sorry but something going wrong at getting widget info, please ping support!'))
}

export type GreetingAnalytics = { [key: string]: { opened: number; sent: number; hash: string } }
export const getGreetingAnalytics = (
  botId: number,
  greetingHashes: string[],
): Promise<GreetingAnalytics | Record<string, never>> => {
  return fetch(`${BASE_URL}/bot/${botId}/widget/greeting-analytics`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(greetingHashes),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => json.data)
    .catch(() => alertError('Sorry but something going wrong at getting widget info, please ping support!'))
}

export function handleWidgetLogoDrop(file, callback) {
  fetch(`${BASE_URL}/file/widgetLogo`, {
    credentials: 'include',
    method: 'POST',
    body: file,
  })
    .then(response => (response.redirected ? (window.location.href = response.url) : response.text()))
    .then(response => {
      callback(response)
    })
    .catch(() => alertError('Fail. Try it later', 10000))
}

export const downloadWidget = botId => {
  window.open(`${BACKEND_URL}/downloadWidget/${botId}`, '_blank')
}
